import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { JiraV2SaveSettingsParams } from '@/services/RestApiClientFactory/modules/ApiClientJiraV2/ApiClientJiraV2.types';
import { getCSRFCookieValue, getUrlEncodedBodyFromJsonBody } from '@/utils/common';
import { config } from '@/config';

export class ApiClientJiraV2 extends ApiClientBase {
  basePath = 'addons/jira_V2';

  async save(params: JiraV2SaveSettingsParams) {
    const { csrfEnabled, csrfTokenName, csrfCookieName } = config;
    const csrfToken = getCSRFCookieValue(csrfCookieName);
    if (csrfEnabled && csrfToken) {
      params[csrfTokenName] = csrfToken;
    }

    const result = await this.fetchData({
      queryString: `${this.basePath}/save`,
      method: 'POST',
      body: getUrlEncodedBodyFromJsonBody(params),
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      apiVersion: 0,
    });

    if (!result.ok) {
      throw Error();
    }

    return result;
  }
}
