import type { AnyMap } from '@/types/types';
import type { Map } from 'immutable';

export enum TaskModelStateFields {
  TASKS = 'tasks',
  TASKS_LOADING_STATUS = 'tasksLoadingStatus',
  TASKS_PICKER = 'taskPicker',
  CREATE_NEW_TASK_LOADING = 'createNewTaskLoading',
  CREATE_NEW_TASK_ERROR = 'createNewTaskError',
  NEW_TASK_CREATED = 'newTaskCreated',
}

type TaskModelStateProps = {
  [TaskModelStateFields.TASKS]: AnyMap;
  [TaskModelStateFields.TASKS_PICKER]: AnyMap;
  [TaskModelStateFields.CREATE_NEW_TASK_LOADING]: boolean;
  [TaskModelStateFields.CREATE_NEW_TASK_ERROR]: string;
  [TaskModelStateFields.TASKS_LOADING_STATUS]: boolean;
  [TaskModelStateFields.NEW_TASK_CREATED]: AnyMap;
};

export type TaskModelState = Map<keyof TaskModelStateProps, TaskModelStateProps[keyof TaskModelStateProps]>;

export enum TaskModelActions {
  FETCH_TASKS = 'FETCH_TASKS',
  FETCH_MISSING_TASKS = 'FETCH_MISSING_TASKS',
  FETCH_TASKS_PICKER_SUCCESS = 'FETCH_TASKS_PICKER_SUCCESS',
  EXPAND_TASK_LIST = 'EXPAND_TASK_LIST',
  FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS',
  CHANGE_LOADING_TASKS_STATUS = 'CHANGE_LOADING_TASKS_STATUS',
  CHECK_TASKS_COHESION = 'CHECK_TASKS_COHESION',
  CREATE_NEW_TASK = 'CREATE_NEW_TASK',
  UPDATE_NEW_TASK_LOADING = 'UPDATE_NEW_TASK_LOADING',
  UPDATE_NEW_TASK_ERROR = 'UPDATE_NEW_TASK_ERROR',
  UPDATE_NEW_TASK_CREATED = 'UPDATE_NEW_TASK_CREATED',
}

export enum ArchivedFilter {
  FILTER_ARCHIVED_ONLY_ACTIVE_TASKS = 'active',
  FILTER_ARCHIVED_ONLY_ARCHIVED_TASKS = 'archived',
  FILTER_ARCHIVED_NO_FILTERING = 'all',
}

export enum LoadingTasksStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  LOADED = 'LOADED',
  IDLE = 'IDLE',
}

export type INewTaskBody = {
  name: string;
  tags?: string;
  parent_id?: string | number;
  external_task_id?: string;
  external_parent_id?: string;
  budgeted?: number;
  note?: string;
  archived?: 0 | 1;
  billable?: 0 | 1;
  budget_unit?: 'hours' | 'fee';
  user_ids?: string; // comma separated user ids to add to task, example: 22,521,2,25
  role?: number; // works only if user_ids are provided - sets given role to given user_ids in created task, example: 5325
};

export type ITaskResponseItem = {
  task_id: string | number;
  name: string;
  parent_id?: string | number;
  external_task_id?: string | number | null;
  external_parent_id?: string | number | null;
  level?: string | number;
  add_date?: string;
  archived?: string | number;
  color?: string;
  tags?: string;
  breadcrumb?: {
    [key: string]: string;
  }[];
  budgeted?: string | number;
  checked_date?: string | null;
  root_group_id?: string | number;
  assigned_to?: string | number | null;
  assigned_by?: string | number;
  due_date?: string | null;
  note?: string | null;
  folder?: string | null;
  repeat?: string | number | null;
  billable?: number;
  budget_unit?: string;
  public_hash?: string | null;
  modify_time?: string | null;
  disabled?: boolean;
};

export type ITaskResponse = Record<string, ITaskResponseItem>;

export type INewTaskResponse = ITaskResponse | null;
